.program-container {
  width: 95%;
  max-width: 848px;
  min-height: calc(100vh - 100px);
  padding-top: 100px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: minmax(275px, 30%) 1fr;
}
.program-bg {
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.program-content {
  display: grid;
  grid-template-rows: minmax(120px, 10%) 1fr auto;
  margin-top: -16px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  
}
.program-divider {
  height: 0;
  border: 1px solid #D8DDE3;
}
.program-divider-2 {
  width: 95%;
  max-width: 614px;
  margin: 0 auto;
  border: 1px solid #D8DDE3;
}
.program-header-right-side {
  flex: 1;
  margin-left: 16px;
}
.program-header-title {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #38424D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.program-header-back {
  cursor: pointer;
  margin-right: 8px;
  color: #2B323B;
  font-size: 32px;
}
.program-desc {
  width: 95%;
  max-width: 614px;
  padding: 28px 0;
  margin: 0 auto;
}
.program-desc-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #38424D;
  margin-bottom: 8px;
}
.program-desc-content {
  margin: 0;
}
.program-desc button {
  color: #8093FC;
  font-size: 16px;
}
.program-desc svg {
  margin-top: -2px;
  font-size: 20px;
}
.program-start-activities-footer {
  width: 95%;
  max-width: 614px;
  padding-top: 16px;
  margin: 0 auto 40px;
}
.program-start-activities-btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  background: #2E47D1;
  padding: 12px 32px;
  border-radius: 6px;
}