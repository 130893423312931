.program-activities-container {
  flex: 1;
  width: 95%;
  height: auto;
  max-width: 614px;
  padding-top: 20px;
  margin: 0 auto;
}
.program-activities-card {
  position: relative;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: minmax(100px, 20%) 1fr;
  background: #EDF0FF;
}
.program-activities-card-mask {
  display: grid;
  place-items: center;
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0.3;
}
.mask-content {
  opacity: 1;
  background-color: transparent;
}
.mask-content-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.mask-content-check-icon {
  display: grid;
  place-items: center;
  font-size: 20px;
  color: #fff;
  background-color: #65CDBB;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.program-activities-card-mask-label {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  opacity: 1;
}
.card-blocked {
  pointer-events: none;
}
.program-activities-img {
  width: 100%;
  height: 108px;
  object-fit: contain;
  border-radius: 8px 0 0 8px;
}
.program-activities-info {
  padding: 16px;
}
.program-activities-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #FBFCFD;
  padding: 4px 16px;
  border-radius: 4px;
  background: #8093FC;
}
.program-activities-info h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #2C3980;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 8px 0 0;
}
.program-activities-qty {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #38424D;
}