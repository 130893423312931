.recommended-programs-container {
  list-style-type: none;
  overflow-x: auto;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  padding: 0 32px 20px 0;
  margin: 0 0 0 32px;
}
.recommended-programs-program {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 205px;
  height: 165px;
  border-radius: 4px;
}
.recommended-programs-program-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}
.recommended-programs-program-title-container {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 30%;
  padding: 10px 14px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #d9d9d9;;
  background-color: #f9faff;
}
.recommended-programs-program-title {
  text-align: center;
  font-size: 14px;
  color: #616261;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}