.nav-container {
  z-index: 10;
  width: 100%;
  position: fixed;
  background-color: #1C2B7D;
  display: grid;
  grid-template-columns: minmax(150px, 20%) 1fr minmax(150px, 20%);
}
.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
}
.nav-navigation {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.nav-user {
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-right: 12px;
}
.nav-user-name {
  font-weight: 700;
  font-size: 14px;
  color: #D5DAF6;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.nav-logout-btn {
  font-weight: 700;
  font-size: 11px;
  color: #65CDBB;
  transform: translateY(-2px);
}
.nav-logout-icon {
  margin-top: -2px;
  font-size: 20px;
}
.nav-logo-heart {
  opacity: 1;
}
.nav-icon {
  cursor: pointer;
  font-size: 32px;
  color: #ABB5ED;
  padding-bottom: 12px;
}
.nav-noti {
  position: relative;
}
.nav-noti-dot {
  position: absolute;
  top: -15px;
  left: 20px;
  color: tomato;
  font-size: 40px;
}
.nav-item {
  padding: 0 12px;
  opacity: 0.5;
}
.nav-item-selected {
  padding: 0 12px;
  opacity: 1;
  border-bottom: 4px solid #ABB5ED;
}