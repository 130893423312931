.enroll-success-bg {
  min-height: 245px;
  background-image: url('assets/images/success-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.enroll-success-img {
 display: block;
 object-fit: cover;
 margin: -200px auto 0;
}
.enroll-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 275px;
  margin: 40px auto;
}
.enroll-success-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #38424D;
  margin: 0 0 8px;
}

.enroll-success-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #607184;
  margin: 0 0 20px;
  text-align: center;
}