.divider {
  margin: 0 32px;
  border: 1px solid #D8DDE3
}
.home-container {
  width: 95%;
  max-width: 848px;
  padding-top: 100px;
  margin: 0 auto;
}
.home-header {
  padding: 20px 36px;
  border-radius: 8px;
  background-color: #fff;
}
.home-header-greet {
  line-height: 100%;
  font-weight: 600;
  font-size: 24px;
  color: #38424D;
  margin: 0;
}
.home-header-name {
  font-size: 20px;
  color: #586CDA;
}
.home-exercises {
  padding: 20px 0;
  margin: 20px 0;
  border-radius: 8px;
  background-color: #fff;
}
.home-exercises-title {
  margin: 20px 0 16px 32px;
  font-weight: 600;
  font-size: 18px;
  color: #38424D;
}