.new-circuit-layout {
  padding-top: 100px;
}
.new-circuit-container {
  width: 95%;
  max-width: 848px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr minmax(150px, 30%);
}
.new-circuit-exercise {
  border-radius: 8px 0 0 8px;
  background-color: #FBFCFD;
}
.new-circuit-video-container {
  position: relative;
}
.new-circuit-exercise-guide-btn {
  position: absolute;
  top: 6px;
  right: 12px;
  font-size: 28px;
  cursor: pointer;
  background-color: #FBFCFD;
  border-radius: 50%;
  color: #495FD7;
}
.exercise-guide-close-btn {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
  color: #38424D;
}
.new-circuit-exercise-list-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 8px 12px;
  border-bottom: 2px solid #D8DDE3;
}
.new-circuit-exercise-list-header-title {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #495FD7;
}
.new-circuit-exercise-list-header-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #38424D;
}
.new-circuit-exercise-list-content {
  list-style-type: none;
  overflow-y: auto;
  max-height: calc(100vh - 174px);
  padding: 0;
  margin: 0;
}
.new-circuit-exercise-list-content-item {
  display: grid;
  grid-template-columns: minmax(50px, 20%) 1fr;
  gap: 8px;
  padding: 12px 8px;
  margin-bottom: 4px;
}
.selected-item {
  background-color: #F4F5F7;
}
.new-circuit-exercise-list-content-img {
  margin: auto 0;
  object-fit: contain;
  border-radius: 8px;
}
.new-circuit-exercise-list-content-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #38424D;
}
.new-circuit-exercise-list-content-duration {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #121C54;
  background: #D5DAF6;
}
.new-circuit-exercise-controls {
  width: 95%;
  max-width: 180px;
  padding: 8px 24px;
  margin: 12px auto 20px;
  border-radius: 20px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.controls-layout {
 width: 45px;
 height: 45px;
 border-radius: 50%;
 display: grid;
 place-items: center;
 font-size: 20px;
 cursor: pointer;
 color: #fff;
}
.controls-back {
  background-color: #DDDFEB;
}
.controls-back-disabled {
  pointer-events: none;
}
.controls-play {
  box-sizing: border-box;
  color: #495FD7;
  border: 4px solid #495FD7;
}
.controls-next {
  background-color: #46C2AD;
}
.new-circuit-exercise-info {
  padding: 12px 16px;
  background-color: #F4F5F7;
}
.new-circuit-exercise-info-title {
  font-weight: 600;
  font-size: 18px;
  color: #38424D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: -4px;
}
.new-circuit-exercise-info-data {
  font-weight: 700;
  font-size: 12px;
  color: #607184;
}
.new-circuit-exercise-info-title-objects {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #607184;
  margin: 8px 0;  
}
.new-circuit-exercise-info-objects {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.new-circuit-exercise-info-object {
  padding: 4px 12px;
  border-radius: 4px;
  background: #3FC0AA;
  font-weight: 700;
  font-size: 12px;
  color: #FBFCFD;
}
.new-circuit-exercise-instructions {
  padding: 16px 10px;
  display: grid;
  grid-template-columns: auto auto;
}
.new-circuit-exercise-instructions-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #607184;
  margin-bottom: 8px;
}
.new-circuit-exercise-instructions-list {
  padding: 0  8px 0 16px;
  margin: 0;
}
.new-circuit-exercise-instructions-list li {
  margin-bottom: 4px;
  font-size: 12px;
  color: #38424D;
}