.alert-container {
  padding: 16px 12px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alert-img {
  display: block;
  object-fit: cover;
}
.alert-subtitle {
  max-width: 250px;
}
.alert-footer {
  display: flex;
  gap: 8px;
}
.cancel-button {
  font-weight: 400;
  font-size: 14px;
  color: #586CDA;
  background-color: #FFFFFF;
  padding: 12px 32px;
  border: 1px solid #586CDA;
  border-radius: 6px;
}
.confirm-button {
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  background: #2E47D1;
  padding: 12px 32px;
  border-radius: 6px;
}