.lectures-container {
  width: 95%;
  max-width: 848px;
  min-height: calc(100vh - 100px);
  padding-top: 100px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: minmax(275px, 30%) 1fr;
}
.lectures-background {
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.lectures-content {
  display: grid;
  grid-template-rows: minmax(120px, 10%) 1fr auto;
  margin-top: -16px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.lectures-header {
  position: sticky;
  top: 70px;
  display: flex;
  align-items: center;
  width: 95%;
  max-width: 614px;
  padding: 28px 0;
  margin: 0 auto;
  background-color: #fff;
}
.lectures-description {
  width: 95%;
  max-width: 614px;
  padding: 0 0 24px;
  margin: 24px auto 0;
}
.lectures-steps {
  position: absolute;
  bottom: -28px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: -3px 0 24px;
}
.lectures-step {
  height: 5px;
  border-radius: 10px;
  background-color: #D8DDE3;
}
.lectures-current-step {
  background-color: #586CDA;
}