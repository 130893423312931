.enroll-program-container {
  position: relative;
  padding: 24px;
}
.enroll-program-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #38424D;
  margin: 0 0 8px;
}
.enroll-program-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #607184;
  margin: 0 0 20px;
}
.enroll-program-close {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
  color: #38424D;
}
.enroll-program-time {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #38424D;
  padding-bottom: 18px;
}
.css-tj5bde-Svg {
  width: 28px;
  transform: rotate(90deg);
}
.enroll-program-title-repeat {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #38424D;
  margin: 28px 0 8px;
}
.enroll-program-btn {
  padding: 12px 40px !important;
}
.enroll-program-days {
  list-style-type: none;
  padding: 0;
  margin: 0 0 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.enroll-program-day {
  width: 44px;
  height: 44px;
  border: 2px solid #8291E3;
  border-radius: 50%;
  background: #F9FAFF;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  opacity: 0.5;
}
.enroll-program-day button {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  color: #8291E3;
}
.enroll-program-selected-day {
 opacity: 1;
}