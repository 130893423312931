.program-card {
  flex-shrink: 0;
  position: relative;
  width: 345px;
  max-height: 104px;
  padding: 16px 24px;
  background: #EDF0FF;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.program-card-blocked {
  opacity: 0.5;
}

.program-card-image {
  border-radius: 50%;
  width: 74px;
  height: 74px;
  object-fit: cover;
}
.program-card-right-side {
  flex: 1;
  margin-left: 16px;
}
.program-card-title {
  margin: 0 28px 4px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #1C2B7D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.program-card-time {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #7D8DA1;
  text-transform: uppercase;
}
.program-card-more-info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #1C2B7D;
  background-color: #FFFFFF;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 20px;
}
.program-card-medal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  width: 44px;
  height: 44px;
  border-radius: 0px 0px 20px 20px;
  background-color: #8093FC;
}