.program-lectures-container {
  flex: 1 0 auto;
  width: 95%;
  max-width: 614px;
  padding-top: 20px;
  margin: 0 auto;
}
.program-lectures-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #2E47D1;
}
.program-lectures-list {
  list-style-type: none;
  padding: 0;
}
.program-lectures-lecture {
  display: grid;
  grid-template-columns: minmax(100px, 20%) 1fr;
  gap: 16px;
  margin-bottom: 20px;
}
.program-lectures-img {
  width: 100%;
  height: auto;
  max-height: 108px;
  object-fit: cover;
  border-radius: 10px;
}
.program-lectures-lecture-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #38424D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: auto 0;
}