.plan-card {
  flex-shrink: 0;
  position: relative;
  width: 345px;
  max-height: 104px;
  padding: 16px 24px;
  background: #3FC0AA;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.plan-card-blocked {
  opacity: 0.5;
}
.plan-card-image {
  max-width: 64px;
  height: auto;
  object-fit: cover;
}
.plan-card-right-side {
  flex: 1;
  margin-left: 16px;
}
.plan-card-title {
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.plan-card-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
}
.time-icon {
  font-size: 16px;
  margin-top: -2px;
}
.plan-card-more-info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #3FC0AA;
  background-color: #FFFFFF;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 20px;
}