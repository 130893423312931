.program-container {
  width: 95%;
  max-width: 848px;
  min-height: 100vh;
  padding-top: 100px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: minmax(275px, 30%) 1fr;
}
.program-background {
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.program-content {
  margin-top: -16px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.program-header {
  display: flex;
  align-items: center;
  width: 95%;
  max-width: 614px;
  padding: 28px 0;
  margin: 0 auto;
}
.program-divider {
  border: 1px solid #D8DDE3
}
.program-header-right-side {
  flex: 1;
  margin-left: 16px;
}
.program-header-title {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #38424D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.program-description {
  width: 95%;
  max-width: 614px;
  padding: 28px 0;
  margin: 0 auto;
}
.program-enroll {
  width: 95%;
  max-width: 614px;
  padding-bottom: 40px;
  margin: 0 auto;
}
.enroll-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  background: #2E47D1;
  padding: 16px 24px;
  border-radius: 6px;
}