/* TODO: After first iteration overwrite colors for healthphy branding */
/*.uk-button-primary {
  background-color: #495fd7 !important;
}

.uk-button-primary:hover {
  background-color: rgba(73, 95, 215, 0.85) !important;
}*/
html,
body {
  background-color: #f9f9f9;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  max-width: 100vw;
}

button {
  cursor: pointer;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  transition: 0.2s;
  background: transparent;
  border: none;
}
p {
  margin: 0 0 12px;
}
span {
  color: initial;
}
a {
  padding: 0 20px;
  opacity: 0.5;
}

.uk-label {
  text-transform: none;
}

.uk-comment-avatar {
  border-radius: 50%;
  height: 80px;
  object-fit: cover;
}

.slots-container {
  overflow-x: scroll;
  display: flex;
  align-items: center;
  height: 60px;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}
/*.slots-container::-webkit-scrollbar {
  display: none;
}*/
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.login-alert {
  margin: 0;
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.incoming-message {
  width: 80%;
  margin-top: -140px;
}

.awaiting-clinic-icon {
  color: #fff;
  background-color: #8093fc;
  display: grid;
  place-items: center;
  z-index: 2;
  animation: phone 1s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite alternate-reverse
}

.scaling {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  animation: scaling 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
}

.circle1 {
  animation-delay: 0s;
}
.circle2 {
  animation-delay: 1s;
}

.videocall-buttons {
  z-index: 11;
  display: flex;
  position: fixed;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
}

.hang-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: #ff1919;
  opacity: 0.5;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.control-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #505050;
  opacity: 0.5;
  cursor: pointer;
}

.control-button:hover {
  opacity: 0.85;
}

.hang-button:hover {
  opacity: 0.9;
}

.slot {
  min-width: 90px;
  height: 26px;
  padding: 0 8px;
  margin: 0 8px 10px 0;
  color: #1e87f0;
  background-color: #F9FAFF;
  box-sizing: border-box;
  border-radius: 6px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);
  -moz-box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);
  box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);
}

.date-slot {
  border-radius: 500px;
  color: #666;
  font-weight: normal;
}

.selected-slot {
  border: 1px solid #1e87f0;
}

.healthphy-container-left-info-name {
  color: #333;
  font-weight: 500;
  font-size: 1rem;
}

.healthphy-container-left-info-email {
  color: #999;
  font-size: 0.85rem;
}

.healthphy-container-left-menu {
  width: 100%;
  flex-grow: 1;
  padding-top: 3vh;
}

.healthphy-container-left-menu li {
  padding-left: 2vw;
}

.healthphy-loader-container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.healthphy-container-left-footer {
  border-top: 1px solid #e5e5e5;
  padding: 0 0 20px 20px;
}

.healthphy-container-left-info-logout {
  color: #495fd7;
  opacity: 0.8;
  font-size: 0.85rem;
  line-height: 1rem;
  margin-top: 5px;
  cursor: pointer;
}

.healthphy-container-left-info-logout:hover {
  color: #495fd7;
  opacity: 0.9;
  text-decoration: underline;
}

.healthphy-loader-container svg * {
  stroke: #495fd7 !important;
}

.initial-container {
  background-color: #1e87f0;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  color: #fff;
}

.icon-clickable {
  cursor: pointer;
}

.icon-clickable:hover {
  opacity: 0.8;
}

.uk-notification-message {
  font-size: 16px;
  background: #fff;
  -webkit-box-shadow: 0 2px 25px 0px rgba(1, 1, 1, 0.27);
  -moz-box-shadow: 0 2px 25px 0px rgba(1, 1, 1, 0.27);
  box-shadow: 0 2px 25px 0px rgba(1, 1, 1, 0.27);
}

.uk-offcanvas-bar {
  padding: 0;
  background-color: white;
  -webkit-box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);
  -moz-box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);
  box-shadow: 3px 0px 2px 0px rgba(209, 209, 209, 0.85);  
}

.uk-offcanvas-bar .uk-nav-default>li>a, .uk-offcanvas-bar .uk-nav-default>li>a.active {
  font-size: 16px;
  color: #999;
}

.uk-offcanvas-bar .uk-nav-default>li>a:hover {
  color: #999;
}

.uk-nav-divider {
  border-top-color: #999 !important;
  opacity: 0.5;
}

@media (orientation: landscape) {
  .OT_publisher {
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    border: 2px solid #fff;
    border-radius: 8px;
  }
}

@media (orientation: portrait) {
  .OT_publisher {
    width: 100vw !important;
    height: 50vh !important;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
  }
  .OT_subscriber {
    width: 100vw !important;
    height: 60vh !important;
  }
  .incoming-message {
    margin-top: -50px;
  }
  .incoming-transition {
    margin-top: -220px;
  }
}

@keyframes scaling {
  0% {
    transform: scale(2);
    background-color: #8093fc;
  }
  100% {
    transform: scale(8);
    background-color: rgba(245, 0, 182, 0);
  }
}

@keyframes phone {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(2.5);
  }
}
