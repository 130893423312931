.description-modal-container {
  padding: 36px 28px 20px;
}
.description-modal-close {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 8px;
  right: 12px;
  color: #38424D;
}